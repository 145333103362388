
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { InstagramLineType } from '@/definitions/chats/settings/instagram/types'
import { useChatsSettings } from '@/compositions/chats/chatsSettings'

export default defineComponent({
  components: {
    TmAvatar,
    TmButton,
  },
  props: {
    list: {
      type: Array as PropType<InstagramLineType[]>,
    },
  },
  setup() {
    const { openInstagramQrCodeModal } = useChatsSettings()
    return {
      openInstagramQrCodeModal,
    }
  },
})
